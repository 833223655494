.mb-3 {
  margin-bottom: 0.75rem;
}

.me-auto {
  margin-inline-end: auto;
}

.p-4 {
  padding: 1rem;
}

html {
  scroll-behavior: smooth;
 }

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.Form-header {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.navbar {
  justify-content: center;
  background-color: #E1E3DC;
  min-height: 5vh;
  font-size: calc(10px + 2vmin);
  font-family: "GFS Didot", serif;
  font-weight: 400;
  font-style: normal;
}

.about-page {
  min-height: 5vh;
  font-size: calc(20px + 2vmin);
  font-family: "GFS Didot", serif;
  font-weight: 400;
  font-style: normal;
}

.about-page-p {
  min-height: 1vh;
  font-size: calc(2px + 2vmin);
  font-family: "GFS Didot", serif;
  font-weight: 50;
  font-style: normal;
}

.parallax-container {
  background-color: #e4e9d9;
}

.scroll-block {
  display: block; 
  position: relative; 
  top: -300px; 
  visibility: hidden;
}

.social-page {
  min-height: 5vh;
  font-size: calc(20px + 2vmin);
  font-family: "GFS Didot", serif;
  font-weight: 400;
  font-style: normal;
}